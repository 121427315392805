import Button from "../common/button";
import { useDispatch } from "react-redux";
import { Flex, Box, Img, Text, Skeleton, Center } from "@chakra-ui/react";

import { closeCart } from "../../redux/actions";

const EmptyCart = () => {
  const dispatch = useDispatch();

  return (
    <Center
      width="100%"
      height="80%"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="80%" alignSelf="center" justifyContent="center">
        <Img
          h="100px"
          _hover={{
            cursor: "pointer",
          }}
          margin="auto"
          fallback={<Skeleton w="100%" />}
          src={`${process.env.NEXT_PUBLIC_NEXTJS_URL}/empty_cart.png`}
        />
        <Flex
          width="100%"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Text textAlign="center" mt="10" mb="5" variant="cartItemDescription">
            Your shopping cart is currently empty.
          </Text>
          <Button
            px="20.5px"
            title="Return to shop"
            backgroundColor="maroon"
            width={{ base: "175px", md: null }}
            handleClick={() => dispatch(closeCart())}
          />
        </Flex>
      </Box>
    </Center>
  );
};
export default EmptyCart;
